.App {
  text-align: center;
  font-size: calc(8px + 1.5vmin);
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.App-logo-container {
  height: 15vmin;
  border-radius: 50%;
  pointer-events: none;
  margin-top: 16px;
  margin-bottom: 50px;
  box-shadow: 5px 5px 15px 5px #ff8080, -9px 5px 15px 5px #ffe488,
    12px 10px 15px 7px #e488ff, -50px -50px 17px 34px rgba(0, 0, 0, 0);
}

.contact {
  margin-top: 200px;
}

.footer {
  background-color: #ff8080;
}

.footer-label {
  font-size: 12px;
}

.footer-greet {
  font-size: 16px;
}

.contact-link {
  color: white;
}

.App-spinner {
  height: 100vh;
  width: 100vw;
}
